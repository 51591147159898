<script setup lang="ts">
import { flattenLastNestedArray, mergePairsByKey } from "@/assets/js/helpers";
import {
	type TAvailabilitiesParams,
	type TNameIDPair,
} from "@/assets/js/types";
import { openAlertModal } from "@/components/modals/modalDefinitions";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";
import dayjs from "dayjs";
import { idText } from "typescript";
import draggable from "vuedraggable";

const store = useDefaultStore();
const apiStore = useApiStore();

const selectedDate = ref<any>(dayjs());
const showInfo = ref<boolean>(false);
const selectedArea = ref<Record<string, any>>({});
// const selectedService = ref<string>("");
const selectedGroup = ref<Record<string, any>>({ id: 0, label: "Sve Grupe" });
// FIXME - fix type
const availabilities = ref<any[]>([]);
const atHomeAvailabilities = ref<any[]>([]);
const labAvailabilities = ref<any[]>([]);
const atHomeDefaults = ref<boolean>(false);
const labDefaults = ref<boolean>(false);
const unsortedOrders = ref<any[]>([]);
const didCallDefaults = ref<boolean>(false);
const orderToShow = ref<any>({});
const serviceOptions = ref<TNameIDPair[]>([
	{
		id: 0,
		label: "Sve usluge",
	},
	{
		id: 1,
		label: "Laboratorija",
	},
	{
		id: 4,
		label: "Pregledi u Vašem domu",
	},
]);
const selectedService = ref<TNameIDPair>({ id: 0, label: "Sve usluge" });

// FIXME - this varaiable is used to prevent the loader edge case
// the loader stopLoading() function is called from this component
// after the API calls of this component are done thus
// stopping the loader before the API calls of the other components are done
// this "hack" solves it by checking if this component is still mounted
// before stopping the loader
const hack = ref<boolean>(false);

onMounted(async () => {
	hack.value = true;
	store.startLoading();
	await getUnsortedOrders();
	await apiStore.callGetGroups({
		service: 1,
	});
	if (hack.value) store.stopLoading();
});

onBeforeUnmount(() => {
	hack.value = false;
});

watch(selectedArea, async () => {
	if (selectedArea.value) {
		// labAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		// atHomeAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		await getAvailabilities(
			selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
		);
	}
});

watch(selectedDate, async () => {
	if (selectedArea.value.id) {
		// labAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		// atHomeAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		await getAvailabilities(
			selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
		);
	}
});

watch(selectedGroup, async () => {
	if (selectedArea.value && selectedService.value.id !== 4) {
		// labAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		// atHomeAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		await getAvailabilities(
			selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
			selectedGroup.value.id,
		);
	}
});

watch(selectedService, async () => {
	if (selectedArea.value.id) {
		// labAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		// atHomeAvailabilities.value = [
		// 	{
		// 		maxcapacity: 0,
		// 		usedcapacity: 0,
		// 	},
		// ];
		await getAvailabilities(
			selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
		);
	}
});

const addSlot = async (time: any, service: number) => {
	// await (didCallDefaults.value
	// 	? addSlotAndInitDay(time, service)
	// 	: handleSlotAdd(time, service));
	if (service === 1) {
		await (labDefaults.value
			? addSlotAndInitDay(time, 1)
			: handleSlotAdd(time, 1));
	} else {
		await (atHomeDefaults.value
			? addSlotAndInitDay(time, 4)
			: handleSlotAdd(time, 4));
	}
	await getAvailabilities(
		selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
	);
};

// TOOD - test this to see if the index is passed correctly to the callback function within the modal onClose callback
const handleRemoveSlot = (indexOfAvailability: number, service: number) => {
	// console.log(indexOfAvailability);

	openAlertModal(
		{
			title: "Brisanje termina",
			text: "Da li želite da obrišete izabrani termin?",
			action: "delete",
		},
		removeSlot,
		indexOfAvailability,
		service,
	);
};

const removeSlot = async (indexOfAvailability: number, service: number) => {
	const paramObj = {
		datetimes: [
			{
				date: selectedDate.value.format("YYYY-MM-DD"),
				from: availabilities.value[indexOfAvailability].from,
				to: availabilities.value[indexOfAvailability].to,
				maxcapacity:
					service === 1
						? labAvailabilities.value[indexOfAvailability].maxcapacity - 1
						: atHomeAvailabilities.value[indexOfAvailability].maxcapacity - 1,
			},
		],
	};
	await apiStore.callPatchAvailability(
		service === 1
			? labAvailabilities.value[indexOfAvailability].avl_id
			: atHomeAvailabilities.value[indexOfAvailability].avl_id,
		paramObj,
	);
	await getAvailabilities(
		selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
	);
};

const toggleInfo = (order: any) => {
	orderToShow.value = order;
	showInfo.value = true;
};

const closeInfo = () => {
	showInfo.value = false;
};

const getAvailabilities = async (
	serviceID: number | string,
	groupID?: number,
) => {
	// labAvailabilities.value = [];
	// atHomeAvailabilities.value = [];
	const paramObj: TAvailabilitiesParams = {
		defaultTimes: false,
		dateFrom: selectedDate.value.format("YYYY-MM-DD"),
		dateTo: selectedDate.value.format("YYYY-MM-DD"),
		// service: returnIDFromLabel(selectedService.value, store.getServices),
		service: serviceID,
		area: selectedArea.value.id,
	};

	if (groupID && groupID !== 0) {
		paramObj.itemgroup = groupID;
	}

	if (selectedService.value.id === 0) {
		let result = await apiStore.callGetAvailabilities(paramObj);
		// console.log(result);

		// Both availabilities are empty
		if (result.length === 0) {
			paramObj.defaultTimes = true;
			atHomeDefaults.value = true;
			labDefaults.value = true;
			result = await apiStore.callGetAvailabilities(paramObj);
			// labAvailabilities.value = JSON.parse(JSON.stringify(result[0]));
			// atHomeAvailabilities.value = JSON.parse(JSON.stringify(result[1]));
			labAvailabilities.value = result[0].map((a: any) => {
				return { ...a };
			});
			atHomeAvailabilities.value = result[1].map((a: any) => {
				return { ...a };
			});
		} else if (result.length === 2) {
			// labAvailabilities.value = JSON.parse(JSON.stringify(result[0]));
			// atHomeAvailabilities.value = JSON.parse(JSON.stringify(result[1]));
			labAvailabilities.value = result[0].map((a: any) => {
				return { ...a };
			});
			atHomeAvailabilities.value = result[1].map((a: any) => {
				return { ...a };
			});
			// atHomeDefaults.value = false;
			// labDefaults.value = false;
		}

		// Lab availabilities are empty
		if (!result[0] || result[0].length === 0) {
			paramObj.defaultTimes = true;
			paramObj.service = 1;
			labDefaults.value = true;
			result = await apiStore.callGetAvailabilities(paramObj, false);
			// labAvailabilities.value = JSON.parse(JSON.stringify(result));
			labAvailabilities.value = result.map((a: any) => {
				return { ...a };
			});
		} else {
			// labDefaults.value = false;
		}

		// At home availabilities are empty
		if (!result[1] || result[1].length === 0) {
			paramObj.defaultTimes = true;
			paramObj.service = 4;
			atHomeDefaults.value = true;
			result = await apiStore.callGetAvailabilities(paramObj, false);
			// atHomeAvailabilities.value = JSON.parse(JSON.stringify(result));
			atHomeAvailabilities.value = result.map((a: any) => {
				return { ...a };
			});
		} else {
			// labDefaults.value = false;
		}

		// merge the two for display
		const nesto = mergePairsByKey(
			labAvailabilities.value,
			atHomeAvailabilities.value,
			"from",
		);

		// flatten the last nested array
		for (const el of nesto) {
			el.orders = flattenLastNestedArray(el.orders);
		}

		// set the display array to the merged array
		availabilities.value = nesto;
	} else {
		const result = await apiStore.callGetAvailabilities(paramObj, false);
		availabilities.value = result;
		if (selectedService.value.id === 1) {
			// labAvailabilities.value = JSON.parse(JSON.stringify(result));
			labAvailabilities.value = result.map((a: any) => {
				return { ...a };
			});
			atHomeAvailabilities.value = [
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
			];
		} else {
			// atHomeAvailabilities.value = JSON.parse(JSON.stringify(result));
			atHomeAvailabilities.value = result.map((a: any) => {
				return { ...a };
			});
			labAvailabilities.value = [
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
				{
					maxcapacity: 0,
					usedcapacity: 0,
				},
			];
		}
	}

	// console.log(availabilities.value);
};

const getUnsortedOrders = async () => {
	const result = await apiStore.callGetUnsortedOrders();
	if (result) unsortedOrders.value = result.data.data;
};

const addSlotAndInitDay = async (indexOfItem: number, service: number) => {
	const tempArray: any[] = [];
	const paramObj = {
		// service: returnIDFromLabel(selectedService.value, store.getServices),
		// area: selectedArea.value.id,
		service,
		area: selectedArea.value.id,
		datetimes: tempArray,
	};

	for (const el of availabilities.value) {
		paramObj.datetimes.push({
			date: selectedDate.value.format("YYYY-MM-DD"),
			from: el.from,
			to: el.to,
			maxcapacity: 0,
		});
	}

	if (service === 1 && labDefaults.value) labDefaults.value = false;
	else if (service === 4 && atHomeDefaults.value) atHomeDefaults.value = false;

	paramObj.datetimes[indexOfItem].maxcapacity++;

	await apiStore.getCancer(paramObj);
};

const handleSlotAdd = async (indexOfAvailability: number, service: number) => {
	const paramObj = {
		datetimes: [
			{
				date: selectedDate.value.format("YYYY-MM-DD"),
				from: availabilities.value[indexOfAvailability].from,
				to: availabilities.value[indexOfAvailability].to,
				maxcapacity:
					service === 1
						? labAvailabilities.value[indexOfAvailability].maxcapacity + 1
						: atHomeAvailabilities.value[indexOfAvailability].maxcapacity + 1,
			},
		],
	};

	await apiStore.callPatchAvailability(
		service === 1
			? labAvailabilities.value[indexOfAvailability].avl_id
			: atHomeAvailabilities.value[indexOfAvailability].avl_id,
		paramObj,
	);
};

const checkMove = (evt: any) => {
	// console.log(evt.draggedContext.element.svc_id);

	if (evt.to.classList.value === "unsorted-list") return true;
	const stringifiedList = JSON.stringify(evt.relatedContext.list);
	// console.log(evt.to.id);

	let destination;

	for (const el of availabilities.value) {
		if (stringifiedList === JSON.stringify(el.orders)) {
			destination = availabilities.value[availabilities.value.indexOf(el)];
			// console.log("dest", destination);
			break;
		}
	}
	if (!destination) {
		useToastStore().openToastError("Greška pri raspoređivanju");
		return;
	}

	// console.log(destination);

	// if (destination.orders.length + 1 > destination.maxcapacity) {
	// 	return false;
	// }

	if (evt.draggedContext.element.svc_id === 1) {
		if (
			labAvailabilities.value[evt.to.id].orders.length + 1 >
			labAvailabilities.value[evt.to.id].maxcapacity
		) {
			return false;
		}
	} else {
		if (
			atHomeAvailabilities.value[evt.to.id].orders.length + 1 >
			atHomeAvailabilities.value[evt.to.id].maxcapacity
		) {
			return false;
		}
	}

	return true;
};

const updateOrder = async (evt: any, index: number) => {
	if (evt.added && index >= 0) {
		const availabilityID =
			evt.added.element.svc_id === 1
				? labAvailabilities.value[index].avl_id
				: atHomeAvailabilities.value[index].avl_id;
		// console.log(evt.added.element.ord_id, availabilities.value[index].avl_id);
		await apiStore.callPatchOrderAvailability(evt.added.element.ord_id, {
			availabilities: [availabilityID],
		});
		store.startLoading();
		await getUnsortedOrders();
		await getAvailabilities(
			selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
		);
		if (hack.value) store.stopLoading();
	} else if (evt.added && index === -1) {
		console.log("to unsorted");
	}
};

const updateUnsorted = async (evt: any) => {
	if (evt.added) {
		await apiStore.callPatchOrderAvailability(evt.added.element.ord_id, {
			availabilities: [],
		});
		store.startLoading();
		await getUnsortedOrders();
		await getAvailabilities(
			selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
		);
		if (hack.value) store.stopLoading();
	}
};

const handleAddTechnician = async (
	evt: any,
	availabilityID: number,
	OrderTimeID: number,
) => {
	const result = await apiStore.callPostUserTicket({
		user: evt.id,
		usertickettype: 3,
		availability: availabilityID,
		ordertime: OrderTimeID,
	});
	// console.log(result);
	await getAvailabilities(
		selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
	);
};

const handleApproveOrder = async (orderTimeID: number) => {
	const result = await apiStore.callPatchOrderApprove(orderTimeID);
	// console.log(result);
	await getAvailabilities(
		selectedService.value.id === 0 ? "1,4" : selectedService.value.id,
	);
};

const resetAvailabilities = () => {
	labAvailabilities.value = [
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
	];
	atHomeAvailabilities.value = [
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
		{
			maxcapacity: 0,
			usedcapacity: 0,
		},
	];
};

// const getOrderInfo = (order: any) => {
// 	const objToReturn: any = {};
// 	objToReturn.patient = {
// 		name: order.ord_fullname,
// 		phone: order.ord_phone,
// 		email: order.ord_email,
// 		address: order.ord_address,
// 	};
// 	for (const el of order.orderitems) {
// 		objToReturn.timeSlot.push({
// 			date: el.avl_date,
// 			time: [{ from: el.avl_timefrom, to: el.avl_timeto }],
// 		});
// 	}

// 	return objToReturn;
// };

// const serviceSelected = async () => {
// 	if (selectedService.value) {
// 		// await apiStore.callGetGroups({ service: selectedService.value.id });
// 		await apiStore.callGetGroups({
// 			service: returnIDFromLabel(selectedService.value, store.getServices),
// 		});
// 	}
// };

// const returnIDFromLabel = (label: string, array: any[]) => {
// 	console.log(label, array);

// 	return array.find((obj: NameIDPair) => obj.label === label)?.id ?? 0;
// };
</script>
<template>
	<LoaderComp v-if="store.getIsLoading" />
	<div v-else class="schedule-wrapper">
		<!-- FIXME - remove the false -->
		<CardInfoModal
			v-if="showInfo"
			status="locked"
			:order-id="orderToShow.ord_id"
			@close-modal="closeInfo"
		/>
		<div class="left-wrap">
			<div class="input-header">
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Usluga</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="selectedService"
							:label="'label'"
							:placeholder="'Odaberite uslugu'"
							:options="serviceOptions"
							:value-prop="'id'"
						/>
					</div>
				</div>
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Opština</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="selectedArea"
							:label="'label'"
							:placeholder="'Odaberite opštinu'"
							:options="store.getAreas"
							:value-prop="'id'"
						/>
					</div>
				</div>
				<!-- <CustomDatepicker v-model="selectedDate" label="Datum" /> -->
				<ModernDatepicker
					label="Datum"
					placeholder="Datum"
					:input-value="selectedDate"
					@update:model-value="selectedDate = $event"
				/>
				<!--<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Usluga</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="selectedService"
							:label="'label'"
							:placeholder="'Odaberite uslugu'"
							:options="store.getServices"
							:value-prop="'id'"
						/>
					</div>
				</div> -->
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Grupa</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="selectedGroup"
							:label="'label'"
							:placeholder="'Odaberite grupu'"
							:options="[{ id: 0, label: 'Sve Grupe' }, ...store.getGroups]"
							:value-prop="'id'"
						/>
					</div>
				</div>
			</div>
			<div class="table-wrap">
				<div
					v-for="(availability, index) in availabilities"
					:key="index"
					class="drop-zone-wrap"
				>
					<span>{{
						availability.from.slice(0, 2) +
						"-" +
						availability.to.slice(0, 2) +
						"h"
					}}</span>
					<draggable
						:id="index"
						class="drop-zone"
						:list="availability.orders"
						:move="checkMove"
						group="slots"
						item-key="ord_id"
						@change="updateOrder($event, index)"
					>
						<!-- @end="checkEnd" -->
						<template #item="{ element }">
							<SlotCard
								:id="element.ord_id"
								:avl-i-d="
									element.svc_id === 1
										? labAvailabilities[index].avl_id
										: atHomeAvailabilities[index].avl_id
								"
								:name="element.ord_fullname"
								:phone="element.ord_phone"
								:locked="element.ordertime_isapproved"
								:technician="element.ticket_usr_fullname"
								:show-btn="true"
								:card-tag="element.orderitems[0].itm_tag"
								@open-modal="toggleInfo(element)"
								@approve-order="handleApproveOrder(element.otm_id)"
								@add-technician="
									handleAddTechnician(
										$event,
										element.svc_id === 1
											? labAvailabilities[index].avl_id
											: atHomeAvailabilities[index].avl_id,
										element.otm_id,
									)
								"
							/>
						</template>
						<template
							v-if="
								labAvailabilities.length > 0 && atHomeAvailabilities.length > 0
							"
							#footer
						>
							<!-- {{ labAvailabilities[index].maxcapacity }} -->
							<SlotCard
								v-for="jindex in labAvailabilities[index].maxcapacity -
								labAvailabilities[index].usedcapacity"
								:key="jindex"
								:serviceID="1"
								free
								@delete="handleRemoveSlot(index, 1)"
							/>
							<SlotCard
								v-for="jindex in atHomeAvailabilities[index].maxcapacity -
								atHomeAvailabilities[index].usedcapacity"
								:key="jindex"
								:serviceID="4"
								free
								@delete="handleRemoveSlot(index, 4)"
							/>
							<div class="btn-wrap">
								<div
									v-if="selectedService.id !== 4"
									class="add-time-slot-btn"
									@click="addSlot(index, 1)"
								>
									<img src="@/assets/image/btn-icon/addBlue.svg" alt="" />
									<span>Otvorite termin</span>
								</div>
								<div
									v-if="selectedService.id !== 1"
									class="add-time-slot-btn"
									@click="addSlot(index, 4)"
								>
									<img src="@/assets/image/btn-icon/addBlue.svg" alt="" />
									<span>Otvorite pregled</span>
								</div>
							</div>
						</template>
					</draggable>
				</div>
			</div>
		</div>
		<div class="right-wrap">
			<div class="unsorted-wrap">
				<div class="unsorted-header">
					<span>Neraspoređeni</span>
				</div>
				<draggable
					class="unsorted-list"
					:list="unsortedOrders"
					:move="checkMove"
					item-key="ord_id"
					group="slots"
					@change="updateUnsorted"
				>
					<template #item="{ element }">
						<SlotCard
							:id="element.ord_id"
							:name="element.ord_fullname"
							:phone="element.orderuser_phone"
							unsorted
						/>
					</template>
				</draggable>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.schedule-wrapper {
	width: 100%;
	height: 100%;
	padding: 15px 0 22px;
	display: flex;

	// justify-content: space-between;

	.left-wrap {
		width: 1385px;
		min-width: 1385px;
		height: 100%;

		.input-header {
			height: 114px;
			display: flex;
			align-items: center;
			padding-left: 27px;

			.dropdown-wrap {
				width: 300px;
			}

			div:not(:last-child) {
				margin-right: 15px;
			}
		}

		.table-wrap {
			width: 100%;
			max-height: 718px;
			overflow-y: auto;

			// height: 100%;

			.drop-zone-wrap {
				min-height: 160px;
				width: 100%;
				border-top: 1px solid #dfdfe8;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 40px 0;

				&:last-child {
					border-bottom: 1px solid #dfdfe8;
				}

				span {
					color: $alt-dark;
					font-family: Archivo, sans-serif;
					font-size: 15px;
					font-weight: 500;
					margin: 0 40px 0 25px;
					min-width: 50px;
				}

				.drop-zone {
					display: flex;
					flex-wrap: wrap;

					.card-wrapper {
						display: flex;
						flex-direction: wrap;

						// justify-content: space-between;
						// width: 100%;

						// margin-bottom: 15px;
					}

					.btn-wrap {
						.add-time-slot-btn {
							width: 235px;
							height: 43px;
							border-radius: 5px;
							background-color: #fff;
							box-shadow: 0 4px 48px 0 rgb(0 0 0 / 11%);
							display: flex;
							align-items: center;
							padding-left: 25px;
							cursor: pointer;

							span {
								color: $alt-dark;
								font-family: Archivo, sans-serif;
								font-size: 15px;
								font-weight: 500;
							}

							&:first-child {
								margin-bottom: 4px;
							}
						}
					}

					:deep(.slot-wrapper) {
						margin-bottom: 15px;
						margin-right: 15px;
					}
				}

				.test-class {
					display: flex;
					justify-content: space-around;
					align-items: center;
					width: 500px;
					height: 100px;
					background-color: red;

					.test-element {
						height: 50px;
						width: 60px;
						background: yellow;
					}
				}
			}
		}
	}

	.right-wrap {
		width: 21.99%;
		height: 100%;
		padding: 0 37px 0 50px;

		.unsorted-wrap {
			width: 283px;
			height: 100%;
			border: 1px solid #dfdfe8;

			.unsorted-header {
				height: 52px;
				width: 100%;
				padding: 15px;
				font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				color: $alt-dark;
			}

			.unsorted-list {
				width: 100%;
				max-height: 750px;
				overflow-y: auto;

				&::-webkit-scrollbar,
				&::-webkit-scrollbar-thumb,
				&::-webkit-scrollbar-track {
					width: 6px;
					border: none;
					background: transparent;
				}

				&::-webkit-scrollbar-button,
				&::-webkit-scrollbar-track-piece,
				&::-webkit-scrollbar-corner,
				&::-webkit-resizer {
					display: none;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #c0c0c0;
				}

				&::-webkit-scrollbar-track {
					background-image: url("@/assets/image/scrolltrack.png");
					background-repeat: repeat-y;
					background-size: contain;
				}

				&:deep(.slot-wrapper) {
					margin-left: 15px;
				}
			}
		}
	}

	// background: red;
}
</style>
